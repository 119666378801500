<template>
	<v-app>
		<v-main>
			<transition name="fade-in-out" appear>
				<router-view></router-view>
			</transition>
		</v-main>
	</v-app>
</template>

<script>
import {loadGTM} from "./cookie-bar";

export default {
	name: 'App',
  created() {
    loadGTM("GTM-KPHVF8V");
  }
};
</script>

<style scoped lang="css">
.fade-in-out-enter,
.fade-in-out-leave-to {
	opacity: 0;
}

.fade-in-out-enter-active,
.fade-in-out-leave-active {
	transition: opacity 2s ease;
}

.fade-in-out-enter-to {
	opacity: 1;
}
</style>
