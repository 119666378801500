import { allowed_date_formats } from './config';

export const overrides = {
	cs: {
		buyout: {
			give_registration_number: 'Zadej číslo tvojí registrace',
			AdditionalInfoForm: {
				new_selected_model: 'Model nového Xboxu',
				select_new_model_name: 'Vyber model svého nově zakoupeného Xboxu',
				new_model_partner_sub_text:
					'Vyber prodejce u kterého byl nový Xbox zakoupen'
			},
			deviceFinder: {
				chose_type: 'Vyber typ',
				chose_brand: 'Vyber značku',
				chose_model: 'Vyber model'
			},
			find_price_of_device: 'Zjisti hodnotu svého zařízení',
			buyout_price: 'Tvoje výkupní cena',
			thankYou: {
				preSale: {
					title:
						'Informativní nacenění bylo odesláno na Tvou e-mailovou adresu pod číslem <b>{0}</b>.'
				}
			}
		},
		validators: {
			required: 'Toto pole je povinné.',
			is_number: 'Toto pole může obsahovat pouze číslice.',
			invalid_bank_account_number: 'Neplatné číslo účtu.',
			invalid_bank_code: 'Neplatný kód banky.',
			min_allowed_value: 'Nejnižší povolená hodnota je: {minValue}.',
			invalid_email: 'Neplatný email',
			valid_post_code: 'Hodnota musí být platné PSČ',
			is_newer_date_valid: 'Datum musí být pozdější než dnešní',
			is_date_valid:
				'Chybný formát data. Použijte prosím jeden z těchto formátů: ' +
				allowed_date_formats.join(', ')
		}
	}
};
