<template>
	<div id="checkPrice" class="byeback__checkPrice">
		<find-device
			id="findDevice"
			v-model="selectedProduct"
			:appraisalSend.sync="appraisalSend"
		/>
		<device-state
			id="deviceState"
			v-model="appraisalSend"
			:product="selectedProduct"
			:qualityTypes="qualityTypes"
			v-if="selectedColor"
		/>
	</div>
</template>

<script>
import FindDevice from '../FindDevice/App';
import DeviceState from '../DeviceState/App';
import DeviceSummary from '../DeviceSummary/App';
import {mapState} from 'vuex';
import {VUEX_BUYOUT} from 'js-mp/src/vue/store/buyout';


export default {
	name: 'TheBuyout',
	components: { FindDevice, DeviceState },
  computed: {
    ...mapState(VUEX_BUYOUT.NAMESPACE, [
      'appraisal',
      'isAppraisalEditable',
      'userBuyoutCode',
      'appraisalErrors',
      'selectedColor',
      'buyoutAdditionalInfo',
      'selectedCategory',
      'appraisalSend',
      'selectedProduct',
      'qualityTypes',
      'displayThankYou',
      'canNotBeBuyout',
      'selectedQuality',
    ]),
  }
};
</script>
