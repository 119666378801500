<template>
	<v-row>
		<v-col xs="6" class="device-condition">
			{{ input.name }}
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-icon color="default" v-on="on"> mdi-information-variant </v-icon>
				</template>
				<span v-html="input.help_text"></span>
			</v-tooltip>
		</v-col>
		<v-col xs="6" v-if="input.type === 'boolean'">
			<v-radio-group v-model="selected" @change="update" row :error-messages="infoMessages">
				<v-radio
					v-for="(choice, index) in input.choices"
					:key="index"
					:label="choice.label"
					:value="choice.value"
					:ripple="false"
					:disabled="disabled"
					:name="slugify(input.name)"
				/>
			</v-radio-group>
		</v-col>
		<v-col xs="6" v-if="input.type === 'list'">
			<v-autocomplete
				v-model="selected"
				item-text="label"
				item-value="value"
				:items="input.choices"
				:disabled="disabled"
				:menu-props="{ closeOnClick: true, offsetY: true }"
				attach
        hide-details="auto"
        :error-messages="infoMessages"
				@change="update"
				:name="slugify(input.name)"
			/>
		</v-col>
	</v-row>
</template>

<script>
import {buyout} from "js-mp";

export default {
	name: 'DynamicFormInput',
	extends: buyout.components.DynamicInput,
};
</script>
