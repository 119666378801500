import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import isEqual from 'lodash/isEqual';

Vue.use(VueRouter);

const routes = [
	{
		path: '/:category?/:brand?/:model?/:color?/:master_product?',
		name: 'home',
		component: Home
	}
];

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from) {
		if (from && to.name === from.name && isEqual(to.params, from.params)) {
			return;
		}
		return { x: 0, y: 0 };
	}
});

export default router;
