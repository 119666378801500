<template>
		<div>
			<slot name="dynamicInputType"
						:acceptableDeviceConditions="acceptableDeviceConditions"
						:qualityDeviceConditions="qualityDeviceConditions"
						:update="update"
			>
			<dynamic-input
				v-for="(input, index) in acceptableDeviceConditions"
				:ref="`input-${input.id}`"
				:key="input.id"
				:input="input"
				:disabled="disabled"
				:index="index + qualityDeviceConditions.length"
				@input="update($event, index + qualityDeviceConditions.length)"
			/>
			</slot>
			<v-row
			v-for="(error, index) in _.get(appraisalErrors, 'device_condition_choices')"
			:key="index"
      >
			  <v-col>{{ error }}</v-col>
		  </v-row>
	</div>
</template>

<script>
import DynamicInput from './DynamicInput';
import {buyout} from "js-mp";

export default {
	name: 'AppraisalForm',
	extends: buyout.components.AppraisalForm,
	components: {
		DynamicInput
	},
};
</script>
