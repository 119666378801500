<template>
	<div class="online-repurchase-summary">
		<div class="selected-product-info">
      <img :src="selectedColor.image">
      <div class="wrapper">
        <div class="headline--summary font-weight-bold color--white">
          {{ $t('console.informative_pricing') }}
        </div>
        <div class="buyout__summaryItem color--white">
          <div class="desc">{{ selectedColor.name }}:</div>
          <div class="price text-right">
            {{ $n(selectedQuality.price, "currency") }}
          </div>
        </div>
        <div v-if="getTotalPriceCorrection !== 0" class="buyout__summaryItem color--white align-center">
          <div class="d-flex align-center">
            <div class="desc mr-2">{{ $t(getTotalPriceCorrection < 0? 'buyout.deduction' : 'buyout.surcharge') }}*</div>
            <v-tooltip bottom>
				      <template v-slot:activator="{ on }">
					      <v-icon color="default" v-on="on"> mdi-information-variant </v-icon>
				      </template>
				      <span v-html="$t('buyout.info_price_deduction')"></span>
			      </v-tooltip>
          </div>
          <div class="price text-right">
            <template v-if="getTotalPriceCorrection < 0" >-&nbsp;</template>
            <template v-if="getTotalPriceCorrection > 0" >+&nbsp;</template>
            {{ $n(Math.abs(getTotalPriceCorrection), "currency") }}
          </div>
        </div>
        <div class="divider"></div>
        <div class="buyout__summaryItem semibold color--white">
          <div class="desc font-weight-bold">
            {{ $t('console.overall_informative_buyout_price') }}
              <v-tooltip bottom v-if="this.displayMinimalPriceHint">
				      <template v-slot:activator="{ on }">
					      <v-icon color="default" v-on="on"> mdi-information-variant </v-icon>
				      </template>
				      <span v-html="$t('buyout.info_minimal_buyout_price')"></span>
			      </v-tooltip>
          </div>
          <div class="price text-right">
            {{ $n(this.finalBuyoutPrice, "currency") }}
          </div>
        </div>
			</div>
		</div>
    <button
      v-if="!displayThankYou && !canNotBeBuyout && selectedQuality"
      id="send"
      class="byeback__btn"
      @click="sendAppraisal"
      :disabled="disableBuyoutSendBtn">
      {{ $t('buyout.send') }}
		</button>
	</div>
</template>

<script>
import {buyout} from "js-mp";
import {VUEX_BUYOUT} from "js-mp/src/vue/store/buyout/index";
import {mapGetters, mapState} from 'vuex';
import {config} from "js-mp/src/vue/config/buyout";
import {GETTER_TYPES} from "js-mp/src/vue/store/buyout/_getter-types";
import {MUTATION_TYPES} from "js-mp/src/vue/store/buyout/_mutation-types";
import {ACTION_TYPES} from "js-mp/src/vue/store/buyout/_action-types";
import isEmpty from "lodash/isEmpty";


export default {
  name: 'DeviceSummary',
  extends: buyout.components.DeviceSummary,
  data() {
    return {
      minimal_buyout_price: config.minimal_buyout_price,
    }
  },
  computed: {
    ...mapState(VUEX_BUYOUT.NAMESPACE,[
      'selectedColor',
      'selectedQuality',
      'displayThankYou',
      'canNotBeBuyout',
      'selectedQuality',
      'disableBuyoutSendBtn',
      'appraisalErrors',
    ]),
    ...mapGetters(VUEX_BUYOUT.NAMESPACE, [
      GETTER_TYPES.GET_BUYOUT_PAYLOAD,
      GETTER_TYPES.SELECTED_BONUS,
      GETTER_TYPES.APPRAISAL_RESULTS,
      GETTER_TYPES.CONTRACT_FORM,
      GETTER_TYPES.APPRAISAL,
		]),
    buyoutPrice(){
      return this.selectedQuality.price + this.getTotalPriceCorrection;
    },
    displayMinimalPriceHint(){
      return this.buyoutPrice < this.minimal_buyout_price;
    },
    finalBuyoutPrice(){
      return Math.max(this.buyoutPrice, this.minimal_buyout_price);
    },
  },
  methods: {
    async sendAppraisal() {
      let payload = this[GETTER_TYPES.GET_BUYOUT_PAYLOAD]
      if (this.userBuyoutCode) this[MUTATION_TYPES.SET_USER_BUYOUT_CODE](null);

      let deviceForm = this[GETTER_TYPES.APPRAISAL_RESULTS].filter(item=>item).map(
        item => item.value
      );

      let deviceQualities = this[GETTER_TYPES.APPRAISAL].device_conditions
        .filter(cond => cond.type === 'quality')
        .map(cond => cond.choices[0]);

      deviceQualities.forEach(deviceQuality => {
        if (
          this[GETTER_TYPES.SELECTED_QUALITY].id === deviceQuality.appraisal_result && !deviceForm.includes(deviceQuality.value)
        )
          deviceForm.push(deviceQuality.value);
      });

      payload['device_condition_choices'] = deviceForm;
			await this.$store.dispatch(
				`${VUEX_BUYOUT.NAMESPACE}/${ACTION_TYPES.SEND_APPRAISAL}`,
				{ payload: this[GETTER_TYPES.GET_BUYOUT_PAYLOAD]}
			);

			if (!isEmpty(this.appraisalErrors.device_condition_choices)) {
				await this.$vuetify.goTo('#appraisalForm');
			}
    },
  },
}
</script>

<style scoped>

</style>