<template>
	<div class="byeback__logo">
		<img
			src="@/assets/images/logo-blue-istyle-byeback-cz.jpg"
			alt="BYEback - iStyle"
		/>
		<div class="text">
			Rozluč se se svým stávajícím zařízením a pořiď si výhodně nové
		</div>
	</div>
</template>

<script>
export default {
	name: 'TheHead'
};
</script>
