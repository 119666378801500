<template>
	<div>
		<form autocomplete="off" id="productSelector" class="byeback__buyout">
			<div class="row">
				<div class="col-12 byeback__buyoutTitle">{{ $t('buyout.deviceFinder.searchDevice.title') }}</div>
				<div class="col-12 byeback__buyoutText">
					{{ $t('buyout.deviceFinder.searchDevice.text') }}
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
					<v-autocomplete
						id="categorySelector"
						v-model="selectedCategory"
						item-text="name"
						item-value="pk"
						:items="categories"
						:placeholder="$t('buyout.deviceFinder.chose_type')"
						:loading="loadingCategories"
						:disabled="!categories.length || isSelectDisabled"
						@change="categoryChange"
						autocomplete="off"
						name="deviceCategory"
						return-object
					/>
				</div>
				<div class="col-md-4">
					<v-autocomplete
						v-model="selectedBrand"
						item-text="name"
						item-value="pk"
						:items="brands"
						:placeholder="$t('buyout.deviceFinder.chose_brand')"
						@change="brandChange"
						:loading="loadingBrands"
						:disabled="!brands.length || !selectedCategory || isSelectDisabled"
						autocomplete="off"
						name="deviceBrand"
						return-object
					/>
				</div>
					<div class="col-md-4">
					<v-autocomplete
						v-model="selectedModel"
						item-text="name"
						item-value="pk"
						:items="models"
						:placeholder="$t('buyout.deviceFinder.chose_model')"
						@change="modelChange"
						:loading="loadingModels"
						:disabled="!models.length || !selectedBrand || isSelectDisabled"
						autocomplete="off"
						name="deviceModel"
						return-object
					/>
          </div>
			</div>
		</form>
		<div id="deviceColors" class="byeback__devices" v-if="selectedModel && displayImages">
			<div class="byeback__device" v-for="color in colors" :key="color.pk">
				<img
					class="byeback__buyout__productImg mx-auto d-block"
					:src="buildAbsoluteURL(color.image ? color.image : notFoundImage)"
					:alt="`phone_image_${color.color.name}`"
					@click="selectProduct(color)"
          @error="imageNotFound"
				/>
				<v-radio-group
					class="radio-group-full-width deviceColors__radioGroup"
					v-model="selectedColor"
					@change="selectProduct()"
          :disabled="isSelectDisabled"
					row
				>
					<v-layout row wrap>
						<v-flex>
							<v-radio
								:label="color.color.name"
								:color="color.color.hex"
								:value="color"
								:ripple="false"
								name="deviceColor"
							/>
						</v-flex>
					</v-layout>
				</v-radio-group>
			</div>
		</div>
	</div>
</template>

<script>
import {buyout} from "js-mp";

export default {
	name: 'FindDevice',
  extends: buyout.components.DeviceFinder,
};
</script>
