import { buyout } from 'js-mp';
import extend from 'lodash/extend';
import router from '@/router';

const config_overrides = {
	currency: 'Kč',
	language: 'cs',
	router: router,
	isURLHandlingEnabled: true,
};
export const allowed_date_formats = [
	'DD.MM.YYYY',
	'DD.MM.YY',
	' DD. MM. YYYY',
	' DD. MM. YY',
	'YYYY-MM-DD'
];
buyout.config = extend(buyout.config, config_overrides);
export const config = buyout.config;
