<template>
	<div>
    <template v-if="appraisalLoadError">
			<h2>{{ appraisalLoadError }}</h2>
		</template>
		<template v-else-if="loadingAppraisal">
      <v-flex>
        <v-progress-circular
            indeterminate
            color="primary"
            size="80"
        />
      </v-flex>
    </template>

    <template v-else-if="appraisal">
		  <div class="row">
		  	<div class="col-12 byeback__buyoutTitle condition mt-10">
		  		Zvol stav svého zařízení
		  	</div>
		  </div>
		  <div
		  	id="deviceCategories"
		  	class="row byeback__deviceState"
		  	v-if="qualities"
		  >
		  	<div
          id="deviceState"
		  		class="col byeback__deviceStateItem"
		  		v-for="quality in qualities"
		  		:key="quality.id"
		  		:class="{ active: qualitySelected && qualitySelected.id === quality.id }"
		  		@click="selectQuality(quality)"
          :data-bosscode="quality.product_code"
		  	>
		  		<div class="row device-state">
		  			<div class="col-12 title--category">
		  				{{ quality.device_state_name }}
		  				<v-tooltip bottom>
		  					<template v-slot:activator="{ on }">
		  						<v-icon color="default" v-on="on">
		  							mdi-information-variant
		  						</v-icon>
		  					</template>
		  					<span>{{ quality.device_state_long_description }}</span>
		  				</v-tooltip>
		  			</div>
		  			<div class="col-12 state">
		  				{{ quality.device_state_description }}
		  			</div>
		  			<div
		  				class="col-12 description"
		  				v-html="quality.device_state_short_description">
		  			</div>
		  			<div class="col-12 priceWrapper">
		  				<div class="title--price">{{ $t('buyout.buyout_price') }}</div>
              <div
								class="price"
								v-if="quality.price || (!quality.price && quality.price === 0)"
							>
								{{ $n(quality.price, "currency") }}
							</div>
		  			</div>
		  		</div>
		  	</div>
		  </div>
      <template
        v-if="selectedQuality || (appraisal && defaultQualityConditions.length)"
      >
		  <div id="deviceForms" class="row byeback__form">
		  	<div class="col-12 col-md-6">1/{{ getTotalSteps }} {{ $t('buyout.detail_pricing') }}</div>
		  	<div class="col-12 col-md-6">2/{{ getTotalSteps }} {{ $t('buyout.data_for_contract') }}</div>
        <div class="col-12 col-md-6">
          <appraisal-form
		  			id="appraisalForm"
		  			:deviceConditions="appraisal.device_conditions"
		  			:defaultConditions="defaultQualityConditions"
		  			class="byeback__formConditions"
		  		/>
		  	</div>
		  	<div class="col-12 col-md-6">
		  		<contract-form
		  			class="byeback__formContact"
		  		/>
		  	</div>
		  	<div
		  		id="canNotBeBuyedOut"
		  		class="row byeback__error"
		  		v-if="canNotBeBuyout"
		  	>
		  		<div class="col text-center">
		  			<div class="byeback__errorText">
		  				{{ $t('buyout.bad_quality') }}
		  			</div>
		  			<div class="byeback__errorTitle">
		  				{{ $t('buyout.can_not_be_buyout') }}
		  			</div>
		  		</div>
		  	</div>
		  </div>
      </template>
      <device-summary
        v-if="!canNotBeBuyout && selectedQuality"
      />
		  <div id="thankYouDialog" class="row byeback__order" v-if="displayThankYou">
		  	<div class="col text-center">
		  		<div class="byeback__orderTitle" v-html="orderTitle"></div>
		  		<div class="byeback__orderText">
		  			{{ $t('buyout.thankYou.onlineBuyout.text') }}
		  		</div>
		  	</div>
		  </div>
    </template>
	</div>
</template>

<script>

import {buyout} from "js-mp";
import {VUEX_BUYOUT} from 'js-mp/src/vue/store/buyout';
import ContractForm from '../ContractForm/App';
import AppraisalForm from '../AppraisalForm/App';
import DeviceSummary from '../DeviceSummary/App';
import { GETTER_TYPES } from 'js-mp/src/vue/store/buyout/_getter-types';
import { mapGetters, mapState } from 'vuex';
import { config } from 'js-mp/src/vue/config/buyout';
import { sleep } from 'js-mp/src/js/buyout';

export default {
	name: 'DeviceState',
  extends: buyout.components.DeviceState,
  computed: {
    ...mapState(VUEX_BUYOUT.NAMESPACE, [
      'appraisal',
      'defaultQualityConditions',
      'userBuyoutCode',
      'selectedQuality',
      'displayThankYou',
      'canNotBeBuyout',
      'loadingAppraisal',
			'qualities',
			'appraisalLoadError',
      'totalSteps',
    ]),
    ...mapGetters(VUEX_BUYOUT.NAMESPACE, [
			GETTER_TYPES.TOTAL_STEPS,
		]),
    	orderTitle() {
			return this.$sanitize(
				this.$t(`buyout.thankYou.onlineBuyout.title`, [this.userBuyoutCode])
			);
		},
    getTotalSteps: {
			get() {
				return this[GETTER_TYPES.TOTAL_STEPS];
			},
		},
  },
  components: {
		AppraisalForm,
    ContractForm,
    DeviceSummary,
	},
  watch: {
		async canNotBeBuyout(newValue) {
			if (newValue && config.scrollToElements) {
				await sleep(100);
				this.$scrollTo('#canNotBeBuyedOut', { offset: -400, duration: 500 });
			}
		}
	}
};
</script>
