<template>
	<v-form>
		<v-container>
			<v-row>
				<v-col>
					<v-text-field
						v-model="form.buyout_device.imei"
						:label="
								$t(
									`buyout.contractForm.${
										selectedCategory.requires_imei ? 'imei' : 'sn'
									}`
								)
							"
						required
						clearable
						:error-messages="_.get(appraisalErrors, 'buyout_device.imei')"
						name="buyout_device_imei"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-textarea
						v-model="form.buyout_device.notes"
						:label="$t('buyout.contractForm.note')"
						rows="1"
						auto-grow
						required
						:error-messages="_.get(appraisalErrors, 'buyout_device.notes')"
						name="buyout_device_notes"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
			</v-row>
			<v-row class="mb-7">
				<v-col>
					<h5>
							{{ $t('buyout.personal_information') }}
          </h5>
					<div class="byeback__divider"></div>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						v-model="form.customer_data.personal_identification_number"
						:label="$t('buyout.contractForm.pin_or_birth_date')"
						required
						:error-messages="
							_.get(appraisalErrors, 'customer_data.personal_identification_number')
						"
						name="customer_data_personal_identification_number"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
				<v-col>
					<v-text-field
						v-model="form.customer_data.identity_card_number"
						:label="$t('buyout.contractForm.identity_card_number')"
						required
						:error-messages="
							_.get(appraisalErrors, 'customer_data.identity_card_number')
						"
						name="customer_data_identity_card_number"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
				<v-col>
					<v-text-field
						v-model="form.customer_data.identity_released_by"
						:label="$t('buyout.contractForm.identity_released_by')"
						required
						:error-messages="
							_.get(appraisalErrors, 'customer_data.identity_released_by')
						"
						name="customer_data_identity_released_by"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
				<v-col>
					<v-menu
						:close-on-content-click="false"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						v-model="menu1"
						min-width="290px"
					>
						<template v-slot:activator="{ on }">
							<v-text-field
								v-model="displayDate"
								:label="$t('buyout.contractForm.identity_valid_to')"
								@blur="
									form.customer_data.identity_valid_to = parseDate(displayDate)
								"
								@focus.native="menu1 = true"
								:error-messages="
									_.get(appraisalErrors, 'customer_data.identity_valid_to')
								"
								name="customer_data_identity_valid_to"
								:disabled="!isAppraisalEditable"
								v-on="on"
							/>
						</template>
						<v-date-picker
							v-model="form.customer_data.identity_valid_to"
							locale="datePickerLocale"
							:allowed-dates="allowedDates"
							@change="menu1 = false"
						/>
					</v-menu>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						v-model="form.customer_data.degree_before_name"
						:label="$t('buyout.contractForm.degree_before_name')"
						required
						:error-messages="_.get(appraisalErrors, 'customer_data.degree_before_name')"
						name="customer_data_degree_before_name"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
				<v-col>
					<v-text-field
						v-model="form.customer_data.first_name"
						:label="$t('buyout.contractForm.first_name')"
						required
						:error-messages="_.get(appraisalErrors, 'customer_data.first_name')"
						name="customer_data_first_name"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
				<v-col>
					<v-text-field
						v-model="form.customer_data.last_name"
						:label="$t('buyout.contractForm.last_name')"
						required
						:error-messages="_.get(appraisalErrors, 'customer_data.last_name')"
						name="customer_data_last_name"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
				<v-col>
					<v-autocomplete
						v-model="form.customer_data.gender"
						:label="$t('buyout.contractForm.gender')"
						item-text="description"
						item-value="identifier"
						:items="genders"
						required
						:error-messages="_.get(appraisalErrors, 'customer_data.gender')"
						name="customer_data_gender"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col md="6">
					<v-text-field
						v-model="form.customer_data.street"
						:label="$t('buyout.contractForm.street')"
						required
						:error-messages="_.get(appraisalErrors, 'customer_data.street')"
						name="customer_data_street"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
				<v-col>
					<v-text-field
						v-model="form.customer_data.city"
						:label="$t('buyout.contractForm.city')"
						required
						:error-messages="_.get(appraisalErrors, 'customer_data.city')"
						name="customer_data_city"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
				<v-col>
					<v-autocomplete
						v-model="form.customer_data.country_identifier"
						:label="$t('buyout.contractForm.country')"
						item-text="short_name"
						item-value="identifier"
						:items="states"
						required
						:placeholder="$t('buyout.contractForm.chose_country')"
						:error-messages="_.get(appraisalErrors, 'customer_data.country_identifier')"
						name="customer_data_country_identifier"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						v-model="form.customer_data.postcode"
						:label="$t('buyout.contractForm.postcode')"
						required
						:error-messages="_.get(appraisalErrors, 'customer_data.postcode')"
						name="customer_data_postcode"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
				<v-col class="phone">
					<v-text-field
						v-model="form.customer_data.phone_number"
						:label="$t('buyout.contractForm.phone_number')"
						required
						:error-messages="_.get(appraisalErrors, 'customer_data.phone_number')"
						name="customer_data_phone_number"
						:disabled="!isAppraisalEditable"
						:placeholder="internationalPhonePrefix"
					/>
				</v-col>
				<v-col>
					<v-text-field
						v-model="form.customer_data.nationality"
						:label="$t('buyout.contractForm.nationality')"
						required
						:error-messages="_.get(appraisalErrors, 'customer_data.nationality')"
						name="customer_data_nationality"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
				<v-col>
					<v-text-field
						v-model="form.customer_data.place_of_birth"
						:label="$t('buyout.contractForm.place_of_birth')"
						required
						:error-messages="_.get(appraisalErrors, 'customer_data.place_of_birth')"
						name="customer_data_place_of_birth"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						v-model="form.customer_data.e_mail"
						:label="$t('buyout.contractForm.e_mail')"
						required
						:error-messages="_.get(appraisalErrors, 'customer_data.e_mail')"
						name="customer_data_e_mail"
						:disabled="!isAppraisalEditable"
					/>
				</v-col>
			</v-row>
			<v-row
				v-for="(error, index) in _.get(appraisalErrors, 'non_field_appraisalErrors')"
				:key="index"
			>
				<v-col>{{ error }}</v-col>
			</v-row>
		</v-container>
	</v-form>
</template>

<script>
import {buyout} from "js-mp"

export default {
	name: 'ContractForm',
	extends:buyout.components.ContractForm
};
</script>
