<template>
	<v-container class="byeback">
		<the-head />
		<br />
		<br />
		<the-buy-out />
		<div class="text-center">
			<a class="byeback__btn" href="https://www.istyle.cz/vykup-online">
				<button class="white--text">Zpět</button>
			</a>
		</div>
	</v-container>
</template>

<script>
import TheHead from '../components/TheHead/App';
import TheBuyOut from '../components/TheBuyout/App';

export default {
	name: 'HomeView',
	mounted() {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
	},
	components: {
		TheHead,
		TheBuyOut
	}
};
</script>
